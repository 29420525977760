// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    ['pagos.gigantier.io']: {
      appName: 'gnt pagos',
      appLogoImage: require('@src/assets/images/guru/gigantier.svg').default,
      faviconImage: require('@src/assets/images/guru/gigantier.png').default,
      showName: false
    },
    default: {
      appName: 'tugurú',
      appLogoImage: require('@src/assets/images/guru/guru.svg').default,
      faviconImage: require('@src/assets/images/guru/guru.png').default,
      showName: true
    }
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export const domainConfig = themeConfig.app[location.hostname]
  ? themeConfig.app[location.hostname]
  : themeConfig.app.default

export default themeConfig
